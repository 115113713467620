import { useState, useEffect, useReducer } from 'react';
import "../styles/loginFormStyle.css";
import Login from '../services/Login';
import { SaveSession, SaveLocal, RedirectionByScreen, ClassNameContainer, ClassNameContainerLeft, ClassNameForm, ClassNameWidthInput } from '../helpers/Helpers';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FaUserAlt } from "react-icons/fa";
import VideoBackground from '../components/videoBackground/videoBackground';
import AzulLogo from "../files/GroundLinkLogo.png";
import Lottie from "lottie-react";
import background_1 from "../animations/background_1.json"
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import format from 'date-fns/format';
import jwt from 'jwt-decode';
import { BiShow, BiHide } from "react-icons/bi";
import GetUser from '../services/GetUser';
import ControlUser from '../components/Modals/ControlUser';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import { decrypt, encrypt } from '../helpers/Helpers';

function LoginForm() {

    const [error, setError] = useState(false);
    const [userAllow, setuserAllow] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [usuario, setUsuario] = useState();
    const [openUserModal, setOpenUserModal] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const navigate = useNavigate();

    //FORMDATA REDUCER
    const formReducer = (state, event) => {
        return {
            ...state,
            [event.name]: event.value
        }
    }
    const [formData, setFormData] = useReducer(formReducer, {});

    const handleChange = event => {
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    //GET IP
    const [IP, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        //console.log(res.data);
        setIP(res.data.IPv4)
    }

    //GET WINDOW SIZE
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        setWindowSize(getWindowSize());
        getData();
    }, []);


    //SEND FUNCTION
    const handleSubmit = (e) => {
        Login({ username: formData.user, password: formData.password, Session: format(new Date(), 'yyyy-MM-dd HH:mm:ss'), IP }).then(response => {
            if(response.Confirmado == 'RE'){
                navigate('/ChangeInitialPassword', { replace: true })
                sessionStorage.setItem('current', encrypt(response.data, process.env.REACT_APP_KEY));
                sessionStorage.setItem('crewCode', response.crewCode);
                
            }else if(response.Confirmado == 'Si'){
                let token = jwt(response.data);
                console.log(token)
                SaveSession(token, response);
                SaveLocal(token, response);
                RedirectionByScreen(windowSize);
            }else if(response.Confirmado == 'No'){
                setuserAllow(response.data)
            }else if(response.Confirmado == 'ERROR'){
               setError(response.data)
            }
        })
        e.preventDefault();
    }

    //SHOW/HIDDE PASSWORD
    const VisibilityPass = () => {
        if (showPass == true) {
            setShowPass(false);
        } else {
            setShowPass(true);
        }
    }

    //GET USER SERVICE
    const GetTypeUser = () => {
        GetUser({ username: formData.user }).then((response) => {
            setUsuario(response)
            if(response[0]?.Confirmado == 'Si'){
                sessionStorage.setItem('crewCode', response[0]?.username);
                navigate('/ChangePasswordbyEmail', { replace: true });
            }else{
                setOpenUserModal(true)
            }
        })
    }


    return (
        <div className="main_container">
            <div>
                <>
                    {openUserModal ? (
                        <ControlUser data={usuario} open={openUserModal} onClose={() => setOpenUserModal()} />

                    ) : (
                        <></>
                    )}
                </>
            </div>
            <div className={ClassNameContainerLeft(windowSize)}>
                <VideoBackground />
            </div>
            <div className={ClassNameContainer(windowSize)}>
                <>
                    {windowSize.innerWidth >= 800 ? (
                        <div className="background_1">
                            <Lottie
                                className="div_lottie"
                                autoplay={false}
                                loop={false}
                                animationData={background_1}
                            />
                        </div>
                    ) : (
                        <div className="background_mobile"></div>
                    )}
                </>
               
                <div className="container_logo_main">
                    <div className="container_logo">
                        <img src={AzulLogo} id="logo_azul" />
                        {/*<div className="container_name">
                            <p><span id="azul">AZUL</span><br /><span id="handling">HANDLING</span></p>
                        </div>*/}
                    </div>
                    {/*<div className="container_slogan">
                        <p id="logo_slogan">HERE TO HELP, HERE TO CARE!</p>
                    </div>*/}
                    {/*
                    moment().isSameOrAfter('2024-04-01') ? (
                        <div className="animate six">
                            <span>5</span><span>&nbsp;</span><span>Y</span><span>E</span><span>A</span><span>R</span><span>S</span>
                            &nbsp;
                            <span>H</span><span>E</span><span>L</span><span>P</span><span>I</span><span>N</span><span>G</span><span>,</span>
                            &nbsp;
                            <span>5</span><span>&nbsp;</span><span>Y</span><span>E</span><span>A</span><span>R</span><span>S</span>
                            &nbsp;
                            <span>C</span><span>A</span><span>R</span><span>I</span><span>N</span><span>G</span><span>!</span>
                        </div>
                    ) : (<></>)
                */}

                </div>

                <div className={ClassNameForm(windowSize)}>
                    <Box sx={{ marginBottom: "10px", marginTop: "25px" }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}>

                            <TextField
                                id="outlined-basic"
                                label="User Name"
                                sx={{ width: ClassNameWidthInput(windowSize) }}
                                name='user'
                                value={formData.user || ""}
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <div id="user_icon"><FaUserAlt id="u_icon" /></div>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined" />
                        </Box>
                    </Box>
                    <Box sx={{ '& > :not(style)': { m: 2 } }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-end',

                        }}>

                            <TextField
                                id="outlined-basic"
                                label="Password"
                                sx={{ width: ClassNameWidthInput(windowSize) }}
                                name='password'
                                value={formData.password || ""}
                                type={showPass ? "text" : "password"}
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <button onClick={VisibilityPass} id="show_pass">{showPass ? <BiHide id="lock_icon" /> : <BiShow id="lock_icon" />}</button>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined" />
                        </Box>
                    </Box>
                    <Stack spacing={2} direction="row" sx={{ paddingBottom: "20px" }}>
                        <Button variant="contained" onClick={handleSubmit}>Login</Button>
                    </Stack>
                    <div className="user_forgot">
                        {
                            formData.user != undefined && formData.user != "" ? (

                                <button style={{ backgroundColor: "transparent", color: "#0277BD", border: "none" }} onClick={GetTypeUser}>
                                    Forgot Password?

                                </button>


                            ) : (
                                <></>
                            )
                        }

                    </div>
                    <div id="error_message">
                        <>
                            {Boolean(error) ? (
                                <p id="passError">
                                    {error}
                                </p>
                            ) : (
                                <></>
                            )
                            }
                        </>
                    </div>
                </div>
                <p style={{ color: "white" }}>v.3.0</p>
            </div>


        </div>
    );
}

export default LoginForm;
